import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { ArrowLeft, Box, Pencil, PlusCircleDotted } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../Button'
import { AppTypeaheadField, DateField, InputField, MetaField, transformErrorForForm } from '../Form'
import { OwnedHwKeyTypeaheadField } from '../OwnedHwKeyTypeahead'

const INITIAL_VALUES = {
  size: '',
  label: '',
  application: null,
  valid_to: null,
  valid_from: null,
  meta: [],
  license_key: null,
}

export default function LicensePoolModal({ onSave, licensePool, isOpen, toggle, onExited, customerId }) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        size: Yup.number().required(
          t('form_required', {
            field: t('license_pool_size'),
          })
        ),
        application: Yup.mixed().required(
          t('form_required', {
            field: t('application'),
          })
        ),
        label: Yup.string(),
        valid_to: Yup.date()
          .required()
          .typeError(
            t('form_required', {
              field: t('license_pool_valid_to'),
            })
          ),
        valid_from: Yup.date()
          .required()
          .typeError(
            t('form_required', {
              field: t('license_pool_valid_from'),
            })
          ),
        meta: Yup.array().of(Yup.array(Yup.string().required(t('fill_meta_error')))),
        license_key: Yup.number()
          .typeError(
            t('form_required', {
              field: t('key_number'),
            })
          )
          .when(['application', 'application_data'], {
            is: (application, application_data) =>
              application?.cloud_licensing_application || application_data?.cloud_licensing_application,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    [t]
  )

  const formik = useFormik({
    validationSchema: schema,
    initialValues: licensePool ?? INITIAL_VALUES,
    onSubmit: (values, actions) =>
      onSave(values).catch((err) => {
        actions.setErrors(transformErrorForForm(err))
      }),
  })

  const { values, handleSubmit, isSubmitting } = formik

  const isCloudLicensing =
    values.application?.cloud_licensing_application || values.application_data?.cloud_licensing_application

  console.log(values, isCloudLicensing)

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onExited}>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <Box className="mr-2" />
                {licensePool
                  ? t('edit_license_pool_title', {
                      label: licensePool.label,
                    })
                  : t('create_license_pool')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Field
                label={t('application')}
                required
                disabled={!!licensePool}
                name={licensePool ? 'application_data' : 'application'}
                component={AppTypeaheadField}
                placeholder={t('search_for_application')}
              />
              <Field label={t('application_label')} name="label" component={InputField} />
              <Field required label={t('license_pool_size')} name="size" component={InputField} type="number" />
              <Field required label={t('license_pool_valid_from')} name="valid_from" component={DateField} />
              <Field required label={t('license_pool_valid_to')} name="valid_to" component={DateField} />
              {isCloudLicensing && (
                <Field
                  name="license_key"
                  component={OwnedHwKeyTypeaheadField}
                  label={t('license_pool_license_key')}
                  filters={{ customer: customerId, to_date__isnull: true, hardware_key__type: 'sentinel-cl' }}
                  disabled={!!licensePool?.id}
                />
              )}
              <div className="mb-2">{t('license_pool_meta')}</div>
              <FieldArray name="meta" component={MetaField} />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button icon={<ArrowLeft />} onClick={toggle} variant="secondary" disabled={isSubmitting} type="button">
                {t('undo')}
              </Button>
              <Button
                icon={licensePool ? <Pencil size={18} /> : <PlusCircleDotted size={18} />}
                variant="success"
                disabled={isSubmitting}
                type="submit"
              >
                {licensePool ? t('update') : t('create')}
              </Button>
            </Modal.Footer>
          </>
        </form>
      </FormikProvider>
    </Modal>
  )
}
