import { endOfDay, format, formatISO, parseISO, startOfDay } from 'date-fns'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useeQueryParams from 'magik-react-hooks/useRouterQueryParams'
import { useEffect, useMemo } from 'react'
import { Form, Tab, Tabs } from 'react-bootstrap'
import {
  Box,
  CardList,
  ChevronRight,
  ExclamationTriangle,
  FileEarmarkArrowUp,
  FileEarmarkLock2,
  Gear,
  Pencil,
  PeopleFill,
  PlusCircleDotted,
  Puzzle,
  Trash,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { useRunRj } from 'react-rocketjump'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import Button from '../../components/Button'
import Detail from '../../components/Detail'
import Layout from '../../components/Layout'
import LicensePoolModal from '../../components/LicensePoolModal'
import ModalConfirm from '../../components/ModalConfirm'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import Paginator from '../../components/Paginator'
import Table from '../../components/Table'
import { LicenceListByPoolState, LicencePoolDetailState, LogsListByPoolState } from '../../state/licensePool'
import AddLicenseModal from './AddLicenseModal'
import ImportLicensesModal from './ImportLicensesModal'

function LicensesTab({
  loading,
  licenses,
  application,
  canAddLicense,
  onAdd,
  onImport,
  onRemove,
  onDisable,
  onEnable,
}) {
  const { t } = useTranslation()
  const [addLicenseModal, addLicenseModalActions] = useModalTrigger()
  const [importLicensesModal, importLicensesModalActions] = useModalTrigger()
  // Flip Flop license action / disabled
  const [ffLicenseModal, ffLicenseModalActions] = useModalTrigger()
  const [rmLicenseModal, rmLicenseModalActions] = useModalTrigger()
  return (
    <div className="mt-2">
      <div className="mb-2 d-flex justify-content-end">
        <Button
          size="sm"
          disabled={!canAddLicense}
          onClick={() => addLicenseModalActions.open()}
          variant="outline-primary"
          icon={<PlusCircleDotted />}
        >
          {t('create_license')}
        </Button>
        <Button
          size="sm"
          className="ml-2"
          disabled={!canAddLicense}
          onClick={() => importLicensesModalActions.open()}
          variant="outline-primary"
          icon={<FileEarmarkArrowUp />}
        >
          {t('import_licenses')}
        </Button>
      </div>
      <Table responsive loading={loading}>
        <thead>
          <tr>
            <th>{t('user_email')}</th>
            <th>{t('user_name')}</th>
            <th>{t('license_enabled')}</th>
            {application.cloud_licensing_application && <th>{t('digital_identity')}</th>}
            <th className="text-right pr-5">
              <Gear />
            </th>
          </tr>
        </thead>
        <tbody>
          {licenses &&
            licenses.map((license) => (
              <tr key={license.id}>
                <td>
                  <Link className="d-flex align-items-center" to={`/users/${license.user}`}>
                    {license.user_data.email}
                  </Link>
                </td>
                <td>{license.user_data.full_name}</td>
                <td>
                  <Form.Check
                    disabled={!license.enabled && !canAddLicense}
                    onChange={() => {
                      ffLicenseModalActions.open(license)
                    }}
                    checked={license.enabled}
                    type="switch"
                    id={`switch-license-${license.id}`}
                  />
                </td>
                {application.cloud_licensing_application && (
                  <td>
                    {license.error_digital_identity ? (
                      <span className="text-danger">
                        <ExclamationTriangle className="mr-1" /> {license.error_digital_identity}
                      </span>
                    ) : (
                      license.digital_identity
                    )}
                    {!license.digital_identity && license.is_valid && !license.error_digital_identity && (
                      <i>{t('pending_emission')}</i>
                    )}
                  </td>
                )}
                <td className="text-right">
                  <Button
                    onClick={() => rmLicenseModalActions.open(license)}
                    icon={<Trash />}
                    variant={'outline-danger'}
                    size="sm"
                  >
                    {t('delete_license')}
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <AddLicenseModal
        onSave={(license) => onAdd.onSuccess(addLicenseModalActions.close).asPromise(license)}
        isOpen={addLicenseModal.isOpen}
        toggle={addLicenseModalActions.toggle}
        onExited={addLicenseModalActions.onClosed}
      />
      <ImportLicensesModal
        onSave={(importPayload) => onImport.onSuccess(importLicensesModalActions.close).asPromise(importPayload)}
        isOpen={importLicensesModal.isOpen}
        toggle={importLicensesModalActions.toggle}
        onExited={importLicensesModalActions.onClosed}
      />
      <ModalConfirm
        title={ffLicenseModal.value?.enabled ? t('disable_license') : t('enable_license')}
        text={
          ffLicenseModal.value
            ? ffLicenseModal.value.enabled
              ? t('disable_license_text', {
                  email: ffLicenseModal.value.user_data.email,
                })
              : t('enable_license_text', {
                  email: ffLicenseModal.value.user_data.email,
                })
            : null
        }
        isOpen={ffLicenseModal.isOpen}
        toggle={ffLicenseModalActions.toggle}
        onExited={ffLicenseModalActions.onClosed}
        confirmBtn={
          <Button
            onClick={() => {
              if (ffLicenseModal.value.enabled) {
                onDisable.onSuccess(ffLicenseModalActions.close).run(ffLicenseModal.value.id)
              } else {
                onEnable.onSuccess(ffLicenseModalActions.close).run(ffLicenseModal.value.id)
              }
            }}
            variant="outline-primary"
          >
            {ffLicenseModal.value?.enabled ? t('disable') : t('enable')}
          </Button>
        }
      />
      <ModalConfirmDelete
        isOpen={rmLicenseModal.isOpen}
        toggle={rmLicenseModalActions.toggle}
        title={t('delete_license')}
        text={
          rmLicenseModal.value
            ? t('delete_license_text', {
                email: rmLicenseModal.value.user_data.email,
              })
            : null
        }
        onExited={rmLicenseModalActions.onClosed}
        onDelete={() => {
          onRemove.onSuccess(rmLicenseModalActions.close).run(rmLicenseModal.value.id)
        }}
      />
    </div>
  )
}

function LogsTab({ logs, loading, pagination, currentPage, goToPage }) {
  const { t } = useTranslation()
  return (
    <div className="mt-2">
      <Table loading={loading}>
        <thead>
          <tr>
            <th>{t('user_email')}</th>
            <th>{t('log_message')}</th>
            <th>{t('log_timestamp')}</th>
          </tr>
        </thead>
        <tbody>
          {logs &&
            logs.map((log) => (
              <tr key={log.id}>
                <td>{log.user_email}</td>
                <td>{log.message}</td>
                <td>{format(parseISO(log.created_at), 'dd-MM-yyyy HH:mm:ss')}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Paginator numPages={pagination.numPages} currentPage={currentPage} goToPage={goToPage} />
    </div>
  )
}

export default function LicensePoolDetail() {
  const { t } = useTranslation()
  const { user } = useAuthUser()
  const history = useHistory()
  const { id, tab } = useParams()
  const handleChangeTab = (tab) => history.push(`/pools/${id}/${tab}`)

  const [{ pool, error }, { updateLicensePool, removeLicensePool, run: fetchPool }] = useRunRj(LicencePoolDetailState, [
    id,
  ])

  const [queryParams, setQueryParams] = useeQueryParams()
  const logsFilters = useMemo(
    () => ({
      page: queryParams.page,
    }),
    [queryParams.page]
  )

  const [
    { licenses, loading: loadingLicenses },
    { addLicense, enableLicense, disableLicense, removeLicense, importLicenses, run: fetchLicenses },
  ] = useRunRj(LicenceListByPoolState, [id])

  const [{ logs, pagination, loading: loadingLogs }, { run: fetchLogs }] = useRunRj(LogsListByPoolState, [
    id,
    logsFilters,
  ])

  const [editModal, editModalActions] = useModalTrigger()
  const [rmModal, rmModalActions] = useModalTrigger()

  useEffect(() => {
    const interval = setInterval(() => {
      fetchLicenses(id)
    }, 60 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [fetchLicenses, id])

  if (error) {
    return <PageError error={error} />
  }

  if (pool === null) {
    return <PageSpinner />
  }

  const canAddLicense = pool.size - pool.used > 0

  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <div className="d-flex align-items-center">
            <h2 className="m-0">
              <Link className="d-flex aling-items-center" to={`/orgs/${pool.organization}`}>
                <PeopleFill className="mr-2" size={31} />
                {pool.organization_data.name}
              </Link>
            </h2>
            <ChevronRight className="ml-3" size={20} />
            <h2 className="m-0 ml-3">
              <Link className="d-flex aling-items-center" to={`/apps/${pool.application}`}>
                <Puzzle size={31} className="mr-2" />
                {pool.application_data.name}
              </Link>
            </h2>
            <ChevronRight className="ml-3" size={20} />
            <h2 className="m-0 ml-3 d-flex align-items-center">
              <Box size={31} className="mr-2" />
              {pool.label === '' ? t('default_pool_label') : pool.label} ({pool.size})
            </h2>
          </div>
          <div>
            {user.is_admin && (
              <>
                <Button
                  onClick={() => editModalActions.open()}
                  className="mr-2"
                  size="sm"
                  variant="outline-primary"
                  icon={<Pencil />}
                >
                  {t('edit_license_pool')}
                </Button>
                <Button
                  disabled={pool.registered !== 0}
                  onClick={() => rmModalActions.open()}
                  size="sm"
                  variant="outline-danger"
                  icon={<Trash />}
                >
                  {t('delete_license_pool')}
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="p-3 d-flex">
          <Detail className="w-50">
            <Detail.Row rowTitle value={t('license_pool_info')} />
            <Detail.Row label={t('organization')} value={pool.organization_data.name} />
            <Detail.Row label={t('application')} value={pool.application_data.name} />
            <Detail.Row label={t('license_pool_size')} value={pool.size} />
            <Detail.Row label={t('license_pool_used')} value={pool.used} />
            <Detail.Row label={t('license_pool_valid_from')} value={format(parseISO(pool.valid_from), 'dd-MM-yyyy')} />
            <Detail.Row label={t('license_pool_valid_to')} value={format(parseISO(pool.valid_to), 'dd-MM-yyyy')} />
            {pool.application_data.cloud_licensing_application && (
              <Detail.Row label={t('key_number')} value={pool.hardware_key_number} />
            )}
          </Detail>
          <Detail className="w-50">
            <Detail.Row rowTitle value={t('license_pool_meta')} />
            {pool.meta.map((metaLine, i) => (
              <Detail.Row key={i} label={metaLine[0]} value={metaLine[1]} />
            ))}
          </Detail>
        </div>

        <Tabs onSelect={handleChangeTab} activeKey={tab} defaultActiveKey="logs" id="pool-tabs">
          <Tab
            eventKey="licenses"
            title={
              <h5 className="d-flex align-items-center">
                <FileEarmarkLock2 className="mr-2" /> {t('licenses_cloud')}
              </h5>
            }
          >
            <LicensesTab
              canAddLicense={canAddLicense}
              licenses={licenses}
              loading={loadingLicenses}
              application={pool.application_data}
              onEnable={enableLicense
                .onSuccess(() => {
                  fetchPool(pool.id)
                  fetchLogs(pool.id, logsFilters)
                })
                .curry(pool.id)}
              onDisable={disableLicense
                .onSuccess(() => {
                  fetchPool(pool.id)
                  fetchLogs(pool.id, logsFilters)
                })
                .curry(pool.id)}
              onRemove={removeLicense
                .onSuccess(() => {
                  fetchPool(pool.id)
                  fetchLogs(pool.id, logsFilters)
                })
                .curry(pool.id)}
              onImport={importLicenses
                .onSuccess(() => {
                  fetchPool(pool.id)
                  fetchLicenses(pool.id)
                  fetchLogs(pool.id, logsFilters)
                })
                .curry(pool.id)}
              onAdd={addLicense
                .onSuccess(() => {
                  fetchPool(pool.id)
                  fetchLicenses(pool.id)
                  fetchLogs(pool.id, logsFilters)
                })
                .curry(pool.id)}
            />
          </Tab>
          {user.is_admin && (
            <Tab
              eventKey="logs"
              title={
                <h5 className="d-flex align-items-center">
                  <CardList className="mr-2" /> Logs
                </h5>
              }
            >
              <LogsTab
                logs={logs}
                pagination={pagination}
                loading={loadingLogs}
                currentPage={+queryParams.page || 1}
                goToPage={(page) => setQueryParams({ page })}
              />
            </Tab>
          )}
        </Tabs>
      </div>
      <LicensePoolModal
        onSave={(pool) =>
          updateLicensePool
            .onSuccess(() => {
              editModalActions.close()
              fetchLicenses(pool.id)
              fetchLogs(pool.id, logsFilters)
            })
            .asPromise({
              ...pool,
              valid_from: formatISO(startOfDay(new Date(pool.valid_from))),
              valid_to: formatISO(endOfDay(new Date(pool.valid_to))),
            })
        }
        isOpen={editModal.isOpen}
        licensePool={pool}
        toggle={editModalActions.toggle}
        onExited={editModalActions.onClosed}
        isCloudLicensing={pool.application_data.cloud_licensing_application}
        customerId={pool.organization_data.id}
      />
      <ModalConfirmDelete
        isOpen={rmModal.isOpen}
        toggle={rmModalActions.toggle}
        title={t('delete_license_poll')}
        text={t('delete_license_pool_text', {
          size: pool.size,
          app_name: pool.application_data.name,
        })}
        onExited={rmModalActions.onClosed}
        onDelete={() => {
          removeLicensePool
            .onSuccess(() => {
              history.push(`/orgs/${pool.organization}`)
            })
            .run(pool)
        }}
      />
    </Layout>
  )
}
